import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { Title, Thumbnail, Container } from "../../../styles";
import { SkillsWrapper, Details } from "../SiteInfo/styles";

import ReactWindowScroll from "./ReactWindowScroll";

const Sites = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const data = useStaticQuery(graphql`
    query {
      allPagesJson {
        edges {
          node {
            path
            name
          }
        }
      }
    }
  `);
  const dataList = data.allPagesJson.edges;
  const array1 = dataList.slice(0, parseInt(dataList.length / 2));
  const array2 = dataList.slice(parseInt(dataList.length / 2), dataList.length);

  return (
    <Container>
      <Title
        as="h2"
        fontSize="29pt"
        fontMobile="24pt"
        direction={isRTL ? "rtl" : ""}
      >
        {t("common:Supported Sites")}
      </Title>
      <SkillsWrapper>
        <Thumbnail maxWidth="100%">
          <ReactWindowScroll listUrls={array1} />
        </Thumbnail>
        <Details>
          <ReactWindowScroll listUrls={array2} />
        </Details>
      </SkillsWrapper>
    </Container>
  );
};

export default Sites;
