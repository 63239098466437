import React,{useContext} from "react";
import { FixedSizeList as List } from "react-window";

import { ThemeContext } from "../../../providers/ThemeProvider";
import RedirectLink from "../../common/RedirectLink";
import {Card} from "../../../styles"

function ReactWindowScroll({ listUrls }) {
  const { theme } = useContext(ThemeContext);

  const Row = ({ index, style }) => {
    const path = listUrls[index].node.path
      .toLowerCase()
      .split(" ")
      .join("-");
    return (
      <li key={index} style={style}>
        <RedirectLink
          to={
            path === "soundcloud"
              ? `/download-${path}-music/`
              : `/download-${path}-video/`
          }
        >
          {listUrls[index].node.name}
        </RedirectLink>
      </li>
    );
  };
  return (
    // <ul style={{ minWidth: "300px" }} style={{border:"1px solid black", borderRadius:"12px",padding:"15px", margin:"0"}}>
    <Card as="ul" theme={theme}>
      <List height={500} itemCount={listUrls.length} itemSize={55}>
        {Row}
      </List>
    </Card>
  );
}

export default ReactWindowScroll;
