import React from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/common/Layout/Layout";
import { SEO } from "../components/common";
import IntroStatic from "../components/landing/StaticPages/Intro";
import Sites from "../components/landing/StaticPages/sites";
// import AppSites from '../components/AppSites';

const IndexPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();

  return (
    <>
      <SEO
        lang={language}
        title={t("common:Supported Sites")}
        description={
          t("home:Video Downloader") +
          ", " +
          t("home:Online Video Downloader[text]")
        }
        keywords={[
          "save video",
          t("home:title"),
          t("home:Download Video"),
          t("home:Online Video Downloader"),
          t("home:Facebook Video Downloader"),
          t("home:Dailymotion Video Downloader"),
        ]}
      />
      <IntroStatic
        title={t("common:Supported Sites")}
        titleContent={t("home:More than 500 Websites Supported")}
      />
      <Sites />
    </>
  );
};

const customProps = {
  localeKey: "home", // same as file name in src/i18n/translations/your-lang/index.js
};

export default Layout(customProps)(IndexPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "home"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
